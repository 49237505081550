import DataApiProvider from "@/services/DataApiProvider";
const data_api_provider = new DataApiProvider();

export default {
  state: {},
  actions: {
    async performGetRawData({ commit }, params) {
      data_api_provider.setAuthorizationHeadersFromLocal();
      try {
        const response = await data_api_provider.requestGetRawData(params);
        return response?.raw;
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
      }
    },
    async performGetAverageData({ commit }, params) {
      data_api_provider.setAuthorizationHeadersFromLocal();
      try {
        const response = await data_api_provider.requestGetAverageData(params);
        return response?.average;
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
      }
    },
    async performGetEnergyData({ commit }, params) {
      data_api_provider.setAuthorizationHeadersFromLocal();
      try {
        const response = await data_api_provider.requestGetEnergyData(params);
        return response?.energy;
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
      }
    },
  },
};
