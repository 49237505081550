import HttpRequest from "@/services/HttpRequest";
import Helpers from "@/helpers";

class DataApiProvider extends HttpRequest {
  constructor() {
    super();
  }

  // GET : Raw Data
  async requestGetRawData(params) {
    let query_string = Helpers.toQueryStringFormat(params.params);
    const request_url = "/analytic/data/raw" + query_string;
    const data = await this.get(request_url);
    return data;
  }

  // GET : Average Data
  async requestGetAverageData(params) {
    let query_string = Helpers.toQueryStringFormat(params.params);
    const request_url = "/analytic/data/average/" + params.frame + query_string;
    const data = await this.get(request_url);
    return data;
  }

  // GET : Energy Data
  async requestGetEnergyData(params) {
    let query_string = Helpers.toQueryStringFormat(params.params);
    const request_url =
      "/analytic/data/energy/" +
      params.frame +
      "/" +
      params.type +
      query_string;
    const data = await this.get(request_url);
    return data;
  }
}

export default DataApiProvider;
