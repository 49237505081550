import SystemApiProvider from "@/services/SystemApiProvider";
const system_api_provider = new SystemApiProvider();

export default {
  state: {
    systems: [],
    sub_systems: [],
    selected_system: null,
  },
  actions: {
    async performGetSystemList({ commit }, site_id) {
      system_api_provider.setAuthorizationHeadersFromLocal();
      try {
        const response = await system_api_provider.requestGetSystemList(
          site_id
        );
        commit("SET_SYSTEMS", response);
        return response?.systems ?? [];
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
      }
    },
    async performGetSubSystemList({ commit }, system_id) {
      system_api_provider.setAuthorizationHeadersFromLocal();
      try {
        const response = await system_api_provider.requestGetSubSystemList(
          system_id
        );
        commit("SET_SUB_SYSTEMS", response);
        return response?.subSystems ?? [];
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
      }
    },
  },
  mutations: {
    SET_SYSTEMS(state, payload) {
      state.systems = payload.systems;
    },
    SET_SELECTED_SYSTEM(state, payload) {
      state.selected_system = payload;
    },
    SET_SUB_SYSTEMS(state, payload) {
      state.sub_systems = payload.subSystems;
    },
  },
};
