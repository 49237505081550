import SiteApiProvider from "@/services/SiteApiProvider";
const site_api_provider = new SiteApiProvider();

export default {
  state: {
    sites: [],
    site_detail: null,
    selected_site: null,
    site_runtime_list: [],
    site_runtime_summary: null,
    system_power: null,
    system_power_weekly: null,
    system_power_monthly: null,
  },
  getters: {
    runtime_percent(state) {
      if (state.site_runtime_summary) {
        let divisor = state.site_runtime_summary.total;
        if (divisor === 0) {
          divisor = 1;
        }
        const on_percent = (state.site_runtime_summary.on / divisor) * 100;
        const off_percent = (state.site_runtime_summary.off / divisor) * 100;
        return {
          on_percent: on_percent.toFixed(0),
          off_percent: off_percent.toFixed(0),
        };
      }
      return null;
    },
  },
  actions: {
    async performGetSiteList({ commit }) {
      site_api_provider.setAuthorizationHeadersFromLocal();
      try {
        const response = await site_api_provider.requestGetSiteList();
        commit("SET_SITES", response);
        return response?.sites ?? [];
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
      }
    },
    async performGetSiteDetail({ commit }, site_id) {
      site_api_provider.setAuthorizationHeadersFromLocal();
      try {
        const response = await site_api_provider.requestGetSiteDetail(site_id);
        commit("SET_SITE_DETAIL", response);
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
      }
    },
    async performGetSiteSystemPower({ commit }, params) {
      site_api_provider.setAuthorizationHeadersFromLocal();
      try {
        const response = await site_api_provider.requestGetSiteSystemPower(
          params
        );
        commit("SET_SYSTEM_POWER", response);
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
      }
    },
    async performGetSiteSystemPowerWeekly({ commit }, params) {
      site_api_provider.setAuthorizationHeadersFromLocal();
      try {
        const response =
          await site_api_provider.requestGetSiteSystemPowerWeekly(params);
        commit("SET_SYSTEM_POWER_WEEKLY", response);
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
      }
    },
    async performGetSiteSystemPowerMonthly({ commit }, params) {
      site_api_provider.setAuthorizationHeadersFromLocal();
      try {
        const response =
          await site_api_provider.requestGetSiteSystemPowerMonthly(params);
        commit("SET_SYSTEM_POWER_MONTHLY", response);
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
      }
    },
    async performGetSiteRunTimeSummary({ commit }, site_id) {
      site_api_provider.setAuthorizationHeadersFromLocal();
      try {
        const response = await site_api_provider.requestGetSiteRunTimeSummary(
          site_id
        );
        commit("SET_SITE_RUNTIME_SUMMARY", response);
        return response;
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
      }
    },
    async performGetSiteRunTimeList({ commit }, site_id) {
      site_api_provider.setAuthorizationHeadersFromLocal();
      try {
        const response = await site_api_provider.requestGetSiteRunTimeList(
          site_id
        );
        commit("SET_SITE_RUNTIME_LIST", response);
        return response;
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
      }
    },
  },
  mutations: {
    SET_SITES(state, payload) {
      state.sites = payload.sites;
    },
    SET_SITE_DETAIL(state, payload) {
      state.site_detail = payload;
    },
    SET_SITE_RUNTIME_LIST(state, payload) {
      state.site_runtime_list = payload?.runtime ?? [];
    },
    SET_SITE_RUNTIME_SUMMARY(state, payload) {
      state.site_runtime_summary = payload;
    },
    SET_SYSTEM_POWER(state, payload) {
      state.system_power = payload;
    },
    SET_SYSTEM_POWER_WEEKLY(state, payload) {
      state.system_power_weekly = payload;
    },
    SET_SYSTEM_POWER_MONTHLY(state, payload) {
      state.system_power_monthly = payload;
    },
    SET_SELECTED_SITE(state, payload) {
      state.selected_site = payload;
    },
  },
};
