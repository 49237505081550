import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

// Highchart
import HighchartsVue from "highcharts-vue";
Vue.use(HighchartsVue);

import Highcharts from "highcharts";
import exportingInit from "highcharts/modules/exporting";
import exportingDataInit from "highcharts/modules/export-data";
import Accessibility from "highcharts/modules/accessibility";

exportingInit(Highcharts);
exportingDataInit(Highcharts);
Accessibility(Highcharts);

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
    downloadPNG: "ดาวน์โหลดรูปภาพ",
    downloadCSV: "ดาวน์โหลดไฟล์ .csv",
  },
});

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.VUE_APP_ANALYTIC_API_KEY,
  authDomain: process.env.VUE_APP_ANALYTIC_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_ANALYTIC_PROJECT_ID,
  storageBucket: process.env.VUE_APP_ANALYTIC_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_ANALYTIC_MSG_SENDER_ID,
  appId: process.env.VUE_APP_ANALYTIC_APP_ID,
  measurementId: process.env.VUE_APP_ANALYTIC_MEASURE_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

Vue.config.productionTip = false;

// Initialize Analytics and get a reference to the service
Vue.prototype.$analytics = getAnalytics();

new Vue({
  router,
  store,
  vuetify,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
