import HttpRequest from "@/services/HttpRequest";

class AuthApiProvider extends HttpRequest {
  constructor() {
    super();
  }

  // POST : Create Session
  async requestSignIn(params) {
    const request_url = "/auth/login";
    const data = await this.create(request_url, params);
    return data;
  }

  // GET : Session Detail by Access Token
  async requestGetSessionDetail() {
    const request_url = "/auth/session";
    const data = await this.get(request_url);
    return data;
  }

  // POST : Verify Old Password
  async requestVerifyOldPassword(params) {
    const request_url = "/auth/verifyPassword";
    const data = await this.create(request_url, params);
    return data;
  }

  // POST : Rest Password by email + send OTP
  async requestForgotPassword(params) {
    const request_url = "/auth/forgetPassword";
    const data = await this.create(request_url, params);
    return data;
  }

  // POST : Verify OTP of Forgot Password
  async requestVerifyForgotPasswordOTP(params) {
    const request_url = "/auth/verifyForgetPassword";
    const data = await this.create(request_url, params);
    return data;
  }

  // PATCH : Change New Password by Password Token
  async requestChangePassword(params) {
    const request_url = "/auth/changePassword";
    const data = await this.update(request_url, params);
    return data;
  }

  // POST : Remove Session
  async requestSignOut() {
    const request_url = "/auth/logout";
    const data = await this.create(request_url);
    return data;
  }
}

export default AuthApiProvider;
