<template>
  <v-app>
    <router-view />
    <ErrorDialog />
    <LoadingOverlay />
  </v-app>
</template>

<script>
import ErrorDialog from "@/components/dialogs/ErrorDialog";
import LoadingOverlay from "@/components/common/LoadingOverlay";

export default {
  name: "App",
  components: {
    ErrorDialog,
    LoadingOverlay,
  },
};
</script>

<style>
.pre-line {
  white-space: pre-line !important;
}

.new-line {
  white-space: normal !important;
  word-wrap: break-word !important;
}

.v-skeleton-loader__list-item {
  background: transparent !important;
}

.v-input .v-label {
  font-size: 1.25em !important;
}

.v-input input {
  font-size: 1.25em !important;
}

.v-input .v-label--active {
  font-size: 1.25em !important;
}

.v-item-group.v-bottom-navigation .v-btn.v-size--default {
  height: inherit;
}

.highcharts-title {
  font-family: "Kittithada";
  font-weight: bold;
  font-size: 1rem !important;
}

.highcharts-menu-item {
  font-size: 0.85rem !important;
}
</style>
