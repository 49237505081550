import dayjs from "dayjs";

import store from "@/store";
import DATA_UNITS from "@/helpers/config/data_units";

let relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);
let updateLocale = require("dayjs/plugin/updateLocale");
dayjs.extend(updateLocale);

dayjs.updateLocale("en", {
  relativeTime: {
    future: "ในอีก %s",
    past: "%s ที่ผ่านมา",
    s: "a few seconds",
    m: "a minute",
    mm: "%d นาที",
    h: "an hour",
    hh: "%d ชั่วโมง",
    d: "a day",
    dd: "%d วัน",
    M: "a month",
    MM: "%d เดือน",
    y: "a year",
    yy: "%d ปี",
  },
});

const k_time_today_format = "HH:mm";
const k_time_only_date_format = "DD/MM/YYYY";
const k_time_default_format = "DD/MM/YYYY เวลา HH:mm";

export default {
  isTokenExist: function () {
    const token = store.state.access_token;
    return token && token != "";
  },
  toThousandFormat: function (value, decimal) {
    if (typeof value === "number") {
      const thousand = Number(value)
        .toFixed(decimal ?? 2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return thousand;
    } else {
      return "-";
    }
  },
  toISOFormat(date) {
    if (date) {
      return dayjs(date).toISOString();
    }
    return "-";
  },
  toDefaultDateTimeFormat(date_time) {
    if (date_time) {
      const is_same = dayjs().isSame(date_time, "day");
      if (is_same) {
        return "วันนี้ " + dayjs(date_time).format(k_time_today_format);
      } else {
        return dayjs(date_time).format(k_time_default_format);
      }
    }
    return "-";
  },
  toOnlyDateFormat(date_time) {
    if (date_time) {
      const is_same = dayjs().isSame(date_time, "day");
      if (is_same) {
        return "วันนี้";
      } else {
        return dayjs(date_time).format(k_time_only_date_format);
      }
    }
    return "-";
  },
  toTimeRangeFormatter(start_time, end_time) {
    const is_same_start = dayjs().isSame(start_time, "day");
    const is_same_end = dayjs().isSame(end_time, "day");

    if (is_same_start && is_same_end) {
      return (
        "วันนี้ " +
        dayjs(start_time).format(k_time_today_format) +
        " - " +
        dayjs(end_time).format(k_time_today_format)
      );
    } else if (is_same_end) {
      return (
        dayjs(start_time).format(k_time_default_format) +
        " - วันนี้ " +
        dayjs(end_time).format(k_time_today_format)
      );
    } else {
      return (
        dayjs(start_time).format(k_time_default_format) +
        " - " +
        dayjs(end_time).format(k_time_default_format)
      );
    }
  },
  toQueryStringFormat: function (filter_object) {
    // Check is Filter objet != null or undefined
    if (filter_object) {
      const filter_keys = Object.keys(filter_object);
      // Check is Filter object is not empty
      if (filter_keys.length > 0) {
        const query_string =
          "?" +
          filter_keys.map((key) => key + "=" + filter_object[key]).join("&");
        return query_string;
      }
    }
    return "";
  },
  getMaxValueFromSeriesData(data) {
    if (!data || data.length === 0) {
      return null;
    }
    const maxValue = Math.max(...data.map((pair) => pair[1]));
    return maxValue;
  },
  getDayNumber(date) {
    // 0 (Sunday) to 6 (Saturday)
    const day = dayjs(date).day();

    // Our chart start with monday as a zero index
    switch (day) {
      case 0:
        return 6;
      default:
        return day - 1;
    }
  },
  getMonthNumber(date) {
    // 0 to 11
    return dayjs(date).month();
  },
  getDurationBetweenDate: function (start_date, end_date) {
    if (end_date) {
      return dayjs(start_date).from(end_date, true);
    }
    return dayjs(start_date).fromNow(true);
  },
  getDataUnitByTag: function (tag) {
    if (tag.includes("_")) {
      const splited_items = tag.split("_");
      tag = splited_items[0];
    }
    if (DATA_UNITS[tag]) {
      return DATA_UNITS[tag];
    }
    return "";
  },
  getDeviceType: function (type) {
    switch (type) {
      case "PRE":
      case "RTD":
      case "FLM":
      case "SEN":
        return "SEN";
      default:
        return type;
    }
  },
  getTimeZoneOffset: function () {
    const current_date = new Date();
    const tz_offset = current_date.getTimezoneOffset() * 60 * 1000;

    return tz_offset;
  },
  getTimeShiftFromNow: function (time_range) {
    // SET : current time as end time
    const end_time = dayjs().toISOString();

    // CALC : get start time value from shifting duration by time range
    let start_time;
    switch (time_range) {
      case "1h":
        start_time = dayjs().subtract(1, "hour").toISOString();
        break;
      case "8h":
        start_time = dayjs().subtract(8, "hour").toISOString();
        break;
      case "24h":
        start_time = dayjs().subtract(24, "hour").toISOString();
        break;
      case "7d":
        start_time = dayjs().subtract(7, "day").toISOString();
        break;

      default:
        start_time = dayjs().subtract(30, "minute").toISOString();
        break;
    }
    return { start_time, end_time };
  },
  getDateTimeFormat: (dateTime, format) => {
    return dayjs(dateTime).format(format);
  },
  getTimestamp: (dateTime) => {
    return dayjs(dateTime).valueOf();
  },
  getPreviousMonth: (number) => {
    let data = [];
    function getMonthThai(index) {
      let temp = [
        "ม.ค.",
        "ก.พ.",
        "มี.ค.",
        "เม.ย.",
        "พ.ค.",
        "มิ.ย.",
        "ก.ค.",
        "ส.ค.",
        "ก.ย.",
        "ต.ค.",
        "พ.ย.",
        "ธ.ค.",
      ];
      return temp[index];
    }
    function getMonthYear(current) {
      const date = new Date();
      if (current !== 0) date.setMonth(date.getMonth() - current);
      const year = date.getFullYear() + 543;
      const month = getMonthThai(date.getMonth());
      const date_format = [month, year].join(" ");
      return date_format;
    }
    for (let index = 0; index <= number; index++) {
      data.push(getMonthYear(index));
    }
    data.reverse();
    return data;
  },
};
