import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);
// Import : Modules
import user from "./modules/user";
import site from "./modules/site";
import alarm from "./modules/alarm";
import system from "./modules/system";
import data from "./modules/data";

import device from "./modules/device";

// Import : HTTP Services
import AuthApiProvider from "@/services/AuthApiProvider";
const auth_api_provider = new AuthApiProvider();

// Setup : VuexPersistence
const vuexLocal = new VuexPersistence({
  key: "seca-storage",
  storage: window.localStorage,
  reducer: (state) => ({ access_token: state.access_token }),
});

export default new Vuex.Store({
  state: {
    access_token: null,
    password_token: null,
    is_loading: false,
    is_error: false,
    error_title: null,
    error_msg: null,
  },
  actions: {
    async performSignIn({ commit }, params) {
      commit("DISPLAY_LOADING");
      try {
        const response = await auth_api_provider.requestSignIn(params);
        commit("HIDE_LOADING");
        if (response.forceChangePassword) {
          commit("SET_PASSWORD_TOKEN", response.passwordToken);
          return "consent";
        } else {
          commit("SET_ACCESS_TOKEN", response.accessToken);
          return "home";
        }
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
      }
    },
  },
  mutations: {
    SET_ACCESS_TOKEN(state, payload) {
      state.access_token = payload;
    },
    CLEAR_ACCESS_TOKEN(state) {
      state.access_token = null;
    },
    SET_PASSWORD_TOKEN(state, payload) {
      state.password_token = payload;
    },
    CLEAR_PASSWORD_TOKEN(state) {
      state.password_token = null;
    },
    DISPLAY_LOADING(state) {
      state.is_loading = true;
    },
    HIDE_LOADING(state) {
      state.is_loading = false;
    },
    DISPLAY_ERROR_DIALOG(state, payload) {
      state.is_error = true;
      state.is_loading = false;
      switch (payload) {
        case 403:
          state.error_title = "พบข้อผิดพลาด-403";
          state.error_msg =
            "User has no authorize, Please contact Data Solution Team (ds@banpunext.co.th)";
          break;

        case 404:
          state.error_title = "พบข้อผิดพลาด-404";
          state.error_msg = "API request path not found";
          break;

        case 500:
          state.error_title = "พบข้อผิดพลาด-500";
          state.error_msg = "Internal Error Occurred";
          break;

        default:
          state.error_title = null;
          state.error_msg = "รายละเอียด: " + payload;
          break;
      }
    },
    HIDE_ERROR_DIALOG(state) {
      state.is_error = false;
    },
  },
  modules: { user, site, system, device, data, alarm },
  plugins: [vuexLocal.plugin],
});
