import HttpRequest from "@/services/HttpRequest";

class SiteApiProvider extends HttpRequest {
  constructor() {
    super();
  }

  // GET : Site List
  async requestGetSiteList() {
    const request_url = "/sites";
    const data = await this.get(request_url);
    return data;
  }

  // GET : Site Detail
  async requestGetSiteDetail(site_id) {
    const request_url = "/sites/" + site_id + "?withData=true";
    const data = await this.get(request_url);
    return data;
  }

  // GET : Site System Power Daily
  async requestGetSiteSystemPower(params) {
    const request_url =
      "/sites/" + params.id + "/system/power/daily?date=" + params.date;
    const data = await this.get(request_url);
    return data;
  }

  // GET : Site System Power Weekly
  async requestGetSiteSystemPowerWeekly(params) {
    const request_url =
      "/sites/" + params.id + "/system/energy/weekly?date=" + params.date;
    const data = await this.get(request_url);
    return data;
  }

  // GET : Site System Power Weekly
  async requestGetSiteSystemPowerMonthly(params) {
    const request_url =
      "/sites/" + params.id + "/system/energy/monthly?date=" + params.date;
    const data = await this.get(request_url);
    return data;
  }

  // GET : Site Run Time Status Summary
  async requestGetSiteRunTimeSummary(site_id) {
    const request_url = "/sites/" + site_id + "/runtime/summary";
    const data = await this.get(request_url);
    return data;
  }
  // GET : Site Run Time Status List
  async requestGetSiteRunTimeList(site_id) {
    const request_url = "/sites/" + site_id + "/runtime";
    const data = await this.get(request_url);
    return data;
  }
}

export default SiteApiProvider;
