import AuthApiProvider from "@/services/AuthApiProvider";
const auth_api_provider = new AuthApiProvider();

export default {
  state: {
    profile: null,
    reset_pwd: null,
    display_change_pwd: false,
  },
  actions: {
    async performGetProfile({ commit }) {
      auth_api_provider.setAuthorizationHeadersFromLocal();
      try {
        const response = await auth_api_provider.requestGetSessionDetail();
        commit("SET_PROFILE", response);
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
      }
    },
    async performVerifyOldPassword({ commit }, password) {
      auth_api_provider.setAuthorizationHeadersFromLocal();
      try {
        const response = await auth_api_provider.requestVerifyOldPassword({
          password,
        });
        return response;
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
      }
    },
    async performForgotPassword({ commit }, params) {
      commit("DISPLAY_LOADING");
      auth_api_provider.setAuthorizationHeadersFromLocal();
      try {
        const response = await auth_api_provider.requestForgotPassword(params);
        commit("HIDE_LOADING");
        commit("SET_RESET_PWD_OBJ", response);
        return true;
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
        return false;
      }
    },
    async performVerifyForgotPasswordOTP({ commit }, params) {
      commit("DISPLAY_LOADING");
      auth_api_provider.setAuthorizationHeadersFromLocal();
      try {
        const response = await auth_api_provider.requestVerifyForgotPasswordOTP(
          params
        );
        commit("HIDE_LOADING");
        commit("SET_PASSWORD_TOKEN", response.passwordToken);
        return true;
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
        return false;
      }
    },
    async performChangePassword({ commit }, params) {
      auth_api_provider.setAuthorizationHeadersFromLocal();
      try {
        const response = await auth_api_provider.requestChangePassword(params);
        if (response.accessToken) {
          commit("SET_ACCESS_TOKEN", response.accessToken);
        }
        return true;
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
        return false;
      }
    },
    async performSignOut({ commit }) {
      auth_api_provider.setAuthorizationHeadersFromLocal();
      commit("DISPLAY_LOADING");
      try {
        await auth_api_provider.requestSignOut();
        commit("HIDE_LOADING");
        commit("SET_ACCESS_TOKEN", null);
        return true;
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
        return;
      }
    },
  },
  mutations: {
    SET_PROFILE(state, payload) {
      state.profile = payload;
    },
    SET_RESET_PWD_OBJ(state, payload) {
      state.reset_pwd = payload;
    },
    DISPLAY_CHANGE_PASSWORD_DIALOG(state) {
      state.display_change_pwd = true;
    },
    HIDE_CHANGE_PASSWORD_DIALOG(state) {
      state.display_change_pwd = false;
    },
  },
};
