import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VOverlay } from 'vuetify/lib/components/VOverlay';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VOverlay,{attrs:{"id":"ErrorDialog","opacity":0.3,"value":_vm.is_error}},[_c(VDialog,{attrs:{"persistent":"","max-width":"380"},model:{value:(_vm.is_error),callback:function ($$v) {_vm.is_error=$$v},expression:"is_error"}},[_c(VCard,{staticClass:"text-center mx-auto",attrs:{"flat":"","rounded":"xl"}},[_c(VCardTitle,{staticClass:"red--text text--darken-1 font-weight-bold justify-center"},[_vm._v(" "+_vm._s(_vm.error_title ? _vm.error_title : "พบข้อผิดพลาด")+" ")]),_c(VDivider),_c(VCardText,{staticClass:"mt-4"},[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.error_msg ? _vm.error_msg : "-"))])]),_c(VDivider),_c(VCardActions,{staticClass:"justify-center"},[_c(VBtn,{attrs:{"rounded":"","depressed":"","color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.hideErrorDialog()}}},[_vm._v(" ยืนยัน ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }