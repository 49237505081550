import DeviceApiProvider from "@/services/DeviceApiProvider";
const device_api_provider = new DeviceApiProvider();

export default {
  actions: {
    async performGetDeviceList({ commit }, sub_system_id) {
      device_api_provider.setAuthorizationHeadersFromLocal();
      try {
        const response = await device_api_provider.requestGetDeviceList(
          sub_system_id
        );
        return response.devices;
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
      }
    },
    async performGetDeviceDetail({ commit }, device_id) {
      device_api_provider.setAuthorizationHeadersFromLocal();
      try {
        const response = await device_api_provider.requestGetDeviceDetail(
          device_id
        );
        return response;
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
      }
    },
    async performGetDeviceRawData({ commit }, params) {
      device_api_provider.setAuthorizationHeadersFromLocal();
      try {
        const response = await device_api_provider.requestGetDeviceRawData(
          params
        );
        return response.raw;
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
      }
    },
  },
};
