import AlarmApiProvider from "@/services/AlarmApiProvider";
const alarm_api_provider = new AlarmApiProvider();

export default {
  state: {
    alarms: [],
    summary: null,
    selected_alarm: null,
    display_ack_alarm: false,
    total_page: null,
  },
  actions: {
    async performGetAlarmSummary({ commit }, params) {
      alarm_api_provider.setAuthorizationHeadersFromLocal();
      try {
        const response = await alarm_api_provider.requestGetAlarmSummary(
          params
        );
        commit("SET_ALARM_SUMMARY", response);
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
      }
    },
    async performGetAlarmList({ commit }, params) {
      alarm_api_provider.setAuthorizationHeadersFromLocal();
      try {
        const response = await alarm_api_provider.requestGetAlarmList(params);
        commit("SET_ALARMS", response);
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
      }
    },
    async performAcknowledgeAlarmByID({ commit }, alarm_id) {
      alarm_api_provider.setAuthorizationHeadersFromLocal();
      try {
        await alarm_api_provider.requestAcknowledgeAlarmByID(alarm_id);
        return true;
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
        return false;
      }
    },
  },
  mutations: {
    SET_ALARMS(state, payload) {
      if (payload.pagination.page !== 0) {
        state.alarms = state.alarms.concat(payload.data);
      } else {
        state.alarms = payload.data;
      }
      state.total_page = payload.pagination.total;
    },
    SET_ALARM_SUMMARY(state, payload) {
      state.summary = payload.count;
    },
    SET_SELECTED_ALARM(state, payload) {
      state.selected_alarm = payload;
    },
    DISPLAY_ALARM_ACK_DIALOG(state) {
      state.display_ack_alarm = true;
    },
    HIDE_ALARM_ACK_DIALOG(state) {
      state.display_ack_alarm = false;
    },
  },
};
