import HttpRequest from "@/services/HttpRequest";

class SystemApiProvider extends HttpRequest {
  constructor() {
    super();
  }

  // GET : System List by Site ID
  async requestGetSystemList(site_id) {
    const request_url =
      "/systems?siteId=" + site_id + "&withAlarm=true&withData=true";
    const data = await this.get(request_url);
    return data;
  }

  // GET : Sub-System List by System ID
  async requestGetSubSystemList(system_id) {
    const request_url =
      "/subSystems?systemId=" + system_id + "&withAlarm=true&withData=true";
    const data = await this.get(request_url);
    return data;
  }
}

export default SystemApiProvider;
