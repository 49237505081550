import Helpers from "@/helpers";

export default [
  {
    path: "/",
    beforeEnter: (to, from, next) => {
      Helpers.isTokenExist() ? next() : next("login");
    },
    component: () =>
      import(
        /* webpackChunkName: "navbar-layout" */ "@/layouts/MainLayout.vue"
      ),
    children: [
      {
        path: "",
        name: "home",
        component: () =>
          import(/* webpackChunkName: "home" */ "@/views/HomeView.vue"),
      },
      {
        path: "/energy-summary",
        name: "energy-summary",
        component: () =>
          import(
            /* webpackChunkName: "energy-summary" */ "@/views/EnergySummaryView.vue"
          ),
      },
      {
        path: "/machine-summary",
        name: "machine-summary",
        component: () =>
          import(
            /* webpackChunkName: "machine-summary" */ "@/views/MachineSummaryView.vue"
          ),
      },
      {
        path: "/devices",
        name: "devices",
        component: () =>
          import(
            /* webpackChunkName: "devices" */ "@/views/DeviceDataView.vue"
          ),
      },
      {
        path: "/devices/info/:id",
        name: "device-info",
        component: () =>
          import(
            /* webpackChunkName: "device-info" */ "@/views/DeviceDetailView.vue"
          ),
      },
      {
        path: "/analytics",
        name: "analytics",
        component: () =>
          import(
            /* webpackChunkName: "analytics" */ "@/views/AnalyticView.vue"
          ),
      },
      {
        path: "/alarms",
        name: "alarms",
        component: () =>
          import(/* webpackChunkName: "alarms" */ "@/views/AlarmView.vue"),
      },
      {
        path: "/profile",
        name: "profile",
        component: () =>
          import(/* webpackChunkName: "profile" */ "@/views/MyProfileView.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/LoginView.vue"),
  },
  {
    path: "/terms-conditions",
    name: "terms-conditions",
    component: () =>
      import(
        /* webpackChunkName: "terms-conditions" */ "@/views/TermsAndConditionsView.vue"
      ),
  },
  {
    path: "/consent",
    name: "consent",
    component: () =>
      import(/* webpackChunkName: "consent" */ "@/views/ConsentView.vue"),
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */ "@/views/ResetPasswordView.vue"
      ),
  },
  {
    path: "/change-password",
    name: "change-password",
    component: () =>
      import(
        /* webpackChunkName: "change-password" */ "@/views/ChangePasswordView.vue"
      ),
  },
];
