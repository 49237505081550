import HttpRequest from "@/services/HttpRequest";
import Helpers from "@/helpers";

class AlarmApiProvider extends HttpRequest {
  constructor() {
    super();
  }

  // GET : Alarm List
  async requestGetAlarmSummary(params) {
    let query_string = Helpers.toQueryStringFormat(params);
    const request_url = "/alarms/summary" + query_string;
    const data = await this.get(request_url);
    return data;
  }

  // GET : Alarm List
  async requestGetAlarmList(params) {
    let query_string = Helpers.toQueryStringFormat(params);
    const request_url = "/alarms" + query_string;
    const data = await this.get(request_url);
    return data;
  }

  // POST : Acknowledged Alarm by ID
  async requestAcknowledgeAlarmByID(alarm_id) {
    const request_url = "/alarms/" + alarm_id + "/ack";
    const data = await this.create(request_url);
    return data;
  }
}

export default AlarmApiProvider;
