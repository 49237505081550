import HttpRequest from "@/services/HttpRequest";

class DeviceApiProvider extends HttpRequest {
  constructor() {
    super();
  }

  // GET : Device List by Sub-System ID
  async requestGetDeviceList(sub_system_id) {
    const request_url =
      "/devices?subSystemId=" + sub_system_id + "&withAlarm=true&withData=true";
    const data = await this.get(request_url);
    return data;
  }

  // GET : Device List by Sub-System ID
  async requestGetDeviceDetail(device_id) {
    const request_url = "/devices/" + device_id + "?withAlarm=true";
    const data = await this.get(request_url);
    return data;
  }

  // GET : Device RAW Data by device IDs
  async requestGetDeviceRawData(params) {
    const request_url =
      "/devices/" +
      params.device_id +
      "/detail?startTime=" +
      params.start_time +
      "&endTime=" +
      params.end_time;
    const data = await this.get(request_url);
    return data;
  }
}

export default DeviceApiProvider;
